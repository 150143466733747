import { Box, Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import styles from './styles/ModalDocumentPdf.module.scss';

interface Props {
    document: any;
    openIframePdf: boolean;
    handleOpenIframePdf: ()=> void;
}

export const ModalDocumentsPdf = ({document, openIframePdf, handleOpenIframePdf}:Props) => {

    return (
        <>
            <Modal data-testid='iframe-documentos' open={openIframePdf} onClose={handleOpenIframePdf}>
                <div id='iframeDocumentos' className={styles.documentModal}>
                    <Button onClick={handleOpenIframePdf} className={styles.btnCloseModal}>
                        <CloseFullscreenIcon/>
                    </Button>
                    <Box className={styles.contentDocument}>
                            <iframe
                                src={'data:application/pdf;base64,' + document} height="100%" width="100%" style={{ overflow: 'auto' }}
                            ></iframe>
                    </Box>
                </div>
            </Modal>
        </>
    )
}
