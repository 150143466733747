import { Box, Button, FormControl, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useAddCreditNotesForm } from '../hooks/useAddCreditNotesForm';
import { RouterGuard, Spinner } from 'shared/components';
import { ICreditNoteAdded } from '../Models/ICreditNoteAdded';
import styles from '../styles/addCreditNotesForm.module.scss';
import { CreditNoteType } from '../Models/ECreditNoteType';
import { DeleteOutline } from '@mui/icons-material';
import { accesRoles } from 'shared';

interface Props {
    readOnly?: boolean;
    creditNoteType: CreditNoteType;
    selectedDocument: string;
    acceptedValue?: string;
}

const fields = [
    { name: 'legalNumber', label: 'Número legal de nota Crédito', type: 'text', maxLength: 20 },
    { name: 'CUDE', label: 'CUDE nota Crédito', type: 'text', maxLength: 100 },
    { name: 'value', label: 'Valor Nota Crédito', type: 'number', maxLength: 15 },
];
const initialValues: ICreditNoteAdded = { legalNumber: '', CUDE: '', value: '' }
const tableHeaders = ['Número legal de nota Crédito', 'CUDE nota Crédito', 'Valor Nota Crédito', 'Acciones'];

export const AddCreditNotesForm = ({ readOnly, creditNoteType, selectedDocument, acceptedValue }: Props) => {

    const { 
        isLoading, 
        validationSchema, 
        creditNotesAdded, 
        formRef, 
        addCreditNote,
        deleteCreditNote
    } = useAddCreditNotesForm({ creditNoteType, selectedDocument, acceptedValue });

    return (
        <>
            {
                !readOnly &&
                <Formik
                    validateOnMount
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={(value) => { addCreditNote(value) }}
                    validationSchema={validationSchema}
                >
                    {
                        formik => (
                            <Form data-testid='form_add_credit_note'>
                                <Grid container>
                                    <Grid container direction={"row"}>
                                        {
                                            fields.map(field => (
                                                <Grid key={field.name} item xs={12} md={4} className={styles.formControlContainer}>
                                                    <FormControl fullWidth className={styles.formControl}>
                                                        <label
                                                            htmlFor={field.name}
                                                            className={styles.formControlLabel}
                                                        >
                                                            {field.label} - Obligatorio
                                                        </label>
                                                        <Field
                                                            id={field.name}
                                                            data-testid={field.name}
                                                            name={field.name}
                                                            type='text'
                                                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (field.type !== 'number') return;

                                                                const dotCount = (e.target.value.match(/\./g) || []).length;
                                                                const numericValue = e.target.value.replace(/[^0-9.]/g, '');

                                                                if (dotCount > 1) {
                                                                    e.target.value = numericValue.replace(/\.$/, '');
                                                                } else {
                                                                    e.target.value = numericValue;
                                                                }
                                                            }}
                                                            placeholder={field.label}
                                                            variant="outlined"
                                                            maxLength={field.maxLength}
                                                            className={
                                                                (Object.keys(formik.errors).includes(field.name) || formik.values[field.name] === '') &&
                                                                    formik.touched[field.name] ? styles.errorField : ''
                                                            }
                                                        />
                                                        <Box className={styles.errorCounterContainer}>
                                                            <span className={styles.spanError}>
                                                                {
                                                                    (Object.keys(formik.errors).includes(field.name) || formik.values[field.name] === '') &&
                                                                        formik.touched[field.name] ? 'Campo obligatorio' : ''
                                                                }
                                                            </span>
                                                            <span className={styles.spanCounter}>
                                                                {formik?.values?.[field.name]?.toString().length || 0}/{field.maxLength}
                                                            </span>
                                                        </Box>
                                                    </FormControl>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                                <Box className={styles.buttonAddContainer}>
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        data-testid='submit-button'
                                        disabled={!(formik.isValid && formik.dirty)}
                                    >
                                        Agregar Nota Crédito
                                    </Button>
                                </Box>
                            </Form>
                        )
                    }
                </Formik>
            }

            {
                creditNotesAdded.length > 0 &&
                <TableContainer
                    className={styles.gridCreditNotes}
                    data-testid="grid-credit-notes"
                >
                    <Table>
                        <TableHead className={styles.tableHead}>
                            <TableRow>
                                {
                                    tableHeaders.map(tableHeader => (
                                        <TableCell key={tableHeader}>{tableHeader}</TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody className={styles.tableBody}>
                            {
                                 creditNotesAdded.map((creditNote, index) => (
                                    <TableRow key={`${creditNote.legalNumber + index}${creditNote.CUDE}${creditNote.value}`}>
                                        <TableCell>{creditNote.legalNumber}</TableCell>
                                        <TableCell>{creditNote.CUDE}</TableCell>
                                        <TableCell>${Number(creditNote.value).toLocaleString("es-CO")}</TableCell>
                                        <TableCell>
                                        <RouterGuard roles={accesRoles.healthProvider} type='component'>
                                            <Tooltip title={"Eliminar"} placement="top" arrow>
                                                <IconButton onClick={()=> deleteCreditNote(creditNote)} data-testid='file-remove' disabled={readOnly}>
                                                    <DeleteOutline/>
                                                </IconButton>
                                            </Tooltip>
                                        </RouterGuard>
                                      </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {isLoading && <Spinner />}
        </>
    )
}
