export const GET_LINK_DOCUMENT = "/v1/document-batches/generate-upload-link";
export const GET_LINK_ACUSE = "/v1/receipt-accuse/generate-upload-link";
export const PROCESS_DOCUMENT_BATCH = "/v1/document-batches";
export const PROCESS_RECEIPT_ACCUSE = "/v1/receipt-accuse";
export const GET_ELECTRONIC_DOCS = "/v1/electronic-documents";
export const GET_ELECTRONIC_RIPS = "/v1/electronic-rips";
export const GET_DATA_PROVIDER = "/v1/health-service-providers";
export const GET_DATA_STATES_DOCS = "/v1/master-records/ELECTRONIC_DOCUMENT_STATES";
export const GET_DATA_TYPES_DOCS = "/v1/master-records/ELECTRONIC_DOCUMENT_TYPE";
export const GET_DATA_USER_TYPES_DOC = "v1/master-records/IDENTIFICATION_TYPES";
export const GET_DATA_PAYMENT_LINE = "/v1/master-records/ELECTRONIC_DOCUMENT_PAYMENT_LINE";
export const GET_DATA_SUPPORT_CONTAINER = "/v1/master-records/SUPPORT_CONTAINER";
export const GET_ACTIVITY_STATUS = "/v1/master-records/ACTIVITY_STATUS";
export const GET_DATA_PROVINCES = "/v1/provinces";
export const GET_DATA_PAYMENTS_STATES = "v1/master-records/PAYMENT_AUTHORIZATION_STATES";
export const GET_EVENTS_HISTORY = "/v1/events_history";
export const REPORTS = "/v1/custom-reports";
export const GET_ELECTRONIC_SUPPORTS = "/supports/associated-support";
export const GET_ELECTRONIC_DOCUMENTS_RELATED = "/related-documents";
export const ELECTRONIC_SUPPORTS_DOWNLOAD = "/supports/download";
export const ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE = "/supports/download-individual-support";
export const GET_DATA_NOTIFICATIONS = "/v1/notifications/by-user";
export const UPDATE_DATA_NOTIFICATIONS = "/v1/notifications/update";
export const GET_DATA_MASTER_GLOSSAS = "/v1/glosses/master-glosses";
export const DOCUMENT_GLOSSAS = "/v1/glosses/electronic-documents";
export const CREDIT_NOTES_ADDED = "/v1/glosses/link-credit-note";
export const RESULT_ACCEPT_VALUE = "/v1/glosses/result-accepte-value";
export const PAYMENT_AUTHORIZATION = "v1/payment-authorization";
export const GLOSSES_ANSWER = "v1/glosses/answer";
export const GLOSSES_AUDIT = "v1/glosses/audit-consolidate";
export const POST_PAYMENT_AUTHORIZATION_DETAILS = "/v1/payment-authorization";
export const GLOSSES_CONSOLIDATE = "v1/glosses/audit-consolidate";
export const GLOSSES = "v1/glosses";
export const GET_COMMENTS = "/v1/comments";
export const GET_CONTACT_NUMBER = '/v1/contract-numbers';
export const WEBSITES = '/v1/websites';
