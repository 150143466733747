import React from 'react';
import style from "../styles/detailedConsultation.module.scss";
import {Typography} from "@mui/material";
import Image from 'shared/assets/index';
import {contentInvoicedItems} from "../helpers/contentTableRowDetailed";
import {DataGrid, esES, GridColDef} from "@mui/x-data-grid";
import {IDocumentTotals, IElectronicDocumentLine} from "../../../shared/models";
import {formatCurrency} from "../../../shared/utils";

interface Props {
    documentLines?: IElectronicDocumentLine[];
    documentTotals?: IDocumentTotals;
}


export const DocumentLinesTable = ({ documentLines, documentTotals }: Props) => {
    const noRows =()=> {
        return(
            <div className='textNoRows' data-testid='content_message_error'>
                Aún no se ha cargado la información correspondiente de esta sección
            </div>
        )
    }
    const renderDocumentLines = () => {
        return !!documentLines ? (
            <div data-testid='content_data_grid'>
                <div className='containerTitleCardTabs'>
                    <img src={Image.iconoCheck} alt=""/>
                    <Typography>Items Facturados</Typography>
                </div>
                <DataGrid
                    rows={documentLines}
                    columns={contentInvoicedItems}
                    className={'tableResultsRips'}
                    data-testid="datagrid-document-items"
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu={true}
                    hideFooterSelectedRowCount={true}
                    hideFooterPagination={true}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    slots={{
                        noRowsOverlay: noRows
                    }}
                />
            </div>
        ) : null;
    }
    const renderTotals = () => {
        const columnsDef: GridColDef[] = [
            {
                field: 'title',
                align: "right",
                headerAlign: "right",
                minWidth: 780,
                cellClassName: "c-black fs-bold",
            },
            {
                field: 'value',
                align: "right",
                headerAlign: "right",
                minWidth: 120,
                cellClassName: "c-black fs-bold",
                renderCell: renderParams => formatCurrency(renderParams.value)
            },
        ];
        const totalRows = [
            {id: 1, title: 'Subtotal', value: documentTotals?.subtotalValue || 0},
            {id: 2, title: 'Total impuestos', value: documentTotals?.taxesTotalValue || 0},
            {id: 3, title: 'Valor recuperado (descuentos)', value: documentTotals?.discountsTotalValue || 0},
            {id: 4, title: 'Total Notas Cr\u00e9dito', value: documentTotals?.creditTotalValue || 0},
            {id: 5, title: 'Total Notas D\u00e9bito', value: documentTotals?.debitTotalValue || 0},
            {id: 6, title: 'Total a pagar', value: documentTotals?.totalBalance || 0}
        ]
        return !!documentTotals ? (
            <div>
                <div className='containerTitleCardTabs'>
                    <img src={Image.iconoMonedas} alt=""/>
                    <Typography >Totales facturados</Typography>
                </div>
                <DataGrid
                    rows={totalRows}
                    columns={columnsDef}
                    className={'tableResultsTotals'}
                    data-testid="datagrid_content_totals"
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu={true}
                    hideFooterSelectedRowCount={true}
                    hideFooterPagination={true}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>
        ) : null;
    }
    return (
        <>
            <div className={`${style.containerBody} containerFormsss containerTableResults`} data-testid={'container_document_items'}>
                {renderDocumentLines()}
                {renderTotals()}
            </div>
        </>
    )
}