export interface IAuditDetails {
    paymentInformation?:IpaymentStructure[];
    paymentValues?:IpaymentValues;
    billingState: IbillingState;
    bill: string;
    totalValue: number;
    readOnly?: boolean;
    glossesGeneralDetailStatus: string;
    valuesPaymentConsolidate?: IpaymentConsolidate;
    provider: IbillingState;
    activityStatus?: {
        id: string;
        code: string;
        name: string;
    },
  }

export interface IbillingState{
    code: string,
    name: string
}

export interface IpaymentValues{
    debitTotalValue: number,
    creditTotalValue: number,
    subtotalValue: number,
    glossesTotalValue: number,
    paymentAuthorizationTotalValue: number,
    paymentAuthorizationCurrentValue: number,
    acceptedConsolidatedValue?: any,
    sustainedConsolidatedValue?: any,
    consolidatedInNoAgreementValue?: any,
}

export interface IpaymentConsolidate {
    initialValues: IInitialValuesConsolidate;
    paymentValues: IpaymentStructure[][]
}

interface IInitialValuesConsolidate {
    acceptedConsolidatedValue?: IpaymentStructure,
    sustainedConsolidatedValue?: IpaymentStructure,
    consolidatedInNoAgreementValue?: IpaymentStructure,
}

export interface IpaymentStructure{
    name: any,
    value: any,
    id?: any,
}

export enum valuesPayment {
    totalNeto= "Total Neto a Pagar",
    totalNotasDebito= "Total Notas débito",
    totalNotasCredito= "Total Notas crédito",
    totalGlossas= "Total glosas",
    totalAuthotizate= "Total autorizado para pagos",
    valorInicialAutorizado= "Valor inicial Autorizado para pagos",
    valorConsolidado= "Valor Consolidado Aceptado",
    valorConsolidadoSustentado= "Valor Consolidado Sustentado",
    valorConsolidadoNoAcuerdo= "Valor Consolidado En no Acuerdo",
    nuevoValorAutorizado= "Nuevo Valor Autorizado para pagos",
  }