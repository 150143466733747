import { Box, Button, FormControl, Grid, IconButton } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { Field, Form, Formik } from 'formik';
import { RichTextEditor, Spinner } from 'shared/components';
import { AddCreditNotesForm } from 'pages/detailed-consultation/components';
import { useFormNoAgreementBalance } from '../hooks/useFormNoAgreementBalance';
import styles from '../styles/formNoAgreementBalance.module.scss';
import { removeHTMLTagsFromText } from 'shared/utils';
import { CreditNoteType } from 'pages/detailed-consultation/Models/ECreditNoteType';
import { Fragment } from 'react';

interface Props {
    selectedDocument: string;
    hideModal: (hide: boolean) => void;
    closeModal: () => void;
}

const fields = [
    { name: 'uploadResponse', label: 'Adjuntar Respuesta Superintendencia', type: 'file', required: false },
    { name: 'acceptedValue', label: 'Valor Aceptado', type: 'number', maxLength: 15, required: true },
    { name: 'authorizedValue', label: 'Valor Autorizado para pago', type: 'number', maxLength: 15, required: true },
];

export const FormNoAgreementBalance = ({ selectedDocument, hideModal, closeModal }: Props) => {

    const {
        initialValues,
        validationSchema,
        acceptedValueHasValue,
        formIsValid,
        formRef,
        readOnly,
        isLoading,
        showConfirmAcceptation,
        checkIfFormIsValid,
        verifyIfAcceptedHasValue,
        downloadFile,
        uploadAttachedDocument
    } = useFormNoAgreementBalance({ hideModal, selectedDocument })
    
    return (
        <Fragment>
            <Formik
                validateOnMount
                enableReinitialize
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={showConfirmAcceptation}
                validationSchema={validationSchema}
            >
                {
                    formik => {
                        checkIfFormIsValid(formik);
                        
                        return (
                            <Form data-testid='form_add_credit_note'>
                                {
                                    readOnly && removeHTMLTagsFromText(formik.values['managementDetail']).length === 0 ?
                                        <></> :
                                        <Fragment>
                                            <label
                                                className={styles.richTextLabel}
                                                data-testid='management-detail-label'
                                            >
                                                Detalle de la gestión de saldo en No Acuerdo
                                            </label>
                                            <Box className={styles.richTextContainer}>
                                                <RichTextEditor
                                                    onChange={(richText) => { formik.setFieldValue('managementDetail', richText.length < 1 ? '<p><br></p>' : richText) }}
                                                    readOnly={readOnly}
                                                    value={formik.values['managementDetail']}
                                                />
                                            </Box>
                                            <span className={styles.richTextCounter}>
                                                {removeHTMLTagsFromText(formik.values['managementDetail']).length}/5000
                                            </span>
                                        </Fragment>
                                }

                                <Grid container className={styles.formContainer}>
                                    <Grid container direction={"row"}>
                                        {
                                            fields.map(field => (
                                                <Grid key={field.name} item xs={12} md={4} className={styles.formControlContainer}>
                                                    <FormControl fullWidth className={styles.formControl}>
                                                        <label
                                                            htmlFor={field.name}
                                                            className={styles.formControlLabel}
                                                        >
                                                            {field.label}{field.required ? ' - Obligatorio' : ''}
                                                        </label>
                                                        {
                                                            field.type === 'file' ?
                                                                <>
                                                                    <Box className={styles.attachFileContainer}>
                                                                        <Button
                                                                            component="label"
                                                                            variant="contained"
                                                                            startIcon={readOnly && formik.values[field.name]?.file?.name === undefined ? '' : <AttachFileIcon />}
                                                                            data-testid={`attach-file-${field.name}`}
                                                                            onClick={() => {downloadFile(formik.values[field.name]?.file)}}
                                                                            className={
                                                                                `
                                                                            ${styles.uploadButton} 
                                                                            ${(Object.keys(formik.errors).includes(field.name) && formik.values[field.name]?.file !== undefined) ?
                                                                                    styles.errorField : ''
                                                                                }`
                                                                            }
                                                                        >
                                                                            <span className={styles.placeholder}>
                                                                                {formik.values[field.name]?.file?.name || (readOnly && formik.values[field.name]?.file?.name === undefined ? '' : field.label)}
                                                                            </span>
                                                                            <Field
                                                                                id={field.name}
                                                                                data-testid={field.name}
                                                                                name={field.name}
                                                                                value={undefined}
                                                                                disabled={readOnly}
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                    formik.setFieldValue(field.name, {
                                                                                        id: null,
                                                                                        file: e.target.files![0]
                                                                                    });
                                                                                    uploadAttachedDocument(e.target.files![0]);
                                                                                    if (e.target) { e.target.value = '' }
                                                                                }}
                                                                                type='file'
                                                                                accept='.pdf'
                                                                            />
                                                                        </Button>
                                                                        {
                                                                            !readOnly && formik.values[field.name]?.file?.name &&
                                                                            <IconButton
                                                                                className={styles.cleanButton}
                                                                                data-testid='remove-file-button'
                                                                                disableRipple
                                                                                disabled={readOnly}
                                                                                onClick={() => {
                                                                                    formik.setFieldValue(field.name, undefined);
                                                                                }}
                                                                            >
                                                                                <CloseIcon />
                                                                            </IconButton>
                                                                        }
                                                                    </Box>
                                                                    <Box className={styles.errorCounterContainer}>
                                                                        <span className={styles.spanError}>
                                                                            {
                                                                                (Object.keys(formik.errors).includes(field.name) && formik.values[field.name]?.file !== undefined) ?
                                                                                    'Archivo no válido' : ''
                                                                            }
                                                                        </span>
                                                                    </Box>
                                                                </>
                                                                :
                                                                <>
                                                                    <Field
                                                                        id={field.name}
                                                                        data-testid={field.name}
                                                                        name={field.name}
                                                                        type='text'
                                                                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            const dotCount = (e.target.value.match(/\./g) || []).length;
                                                                            const numericValue = e.target.value.replace(/[^0-9.]/g, '');

                                                                            if (dotCount > 1) {
                                                                                e.target.value = numericValue.replace(/\.$/, '');
                                                                            } else {
                                                                                e.target.value = numericValue;
                                                                            }

                                                                            verifyIfAcceptedHasValue(field.name, e.target.value);
                                                                        }}
                                                                        placeholder={field.label}
                                                                        variant="outlined"
                                                                        maxLength={field.maxLength}
                                                                        className={
                                                                            (Object.keys(formik.errors).includes(field.name) || formik.values[field.name] === '') &&
                                                                                formik.touched[field.name] ? styles.errorField : ''
                                                                        }
                                                                        disabled={readOnly}
                                                                    />
                                                                    <Box className={styles.errorCounterContainer}>
                                                                        <span className={styles.spanError}>
                                                                            {
                                                                                (Object.keys(formik.errors).includes(field.name) || formik.values[field.name] === '') &&
                                                                                    formik.touched[field.name] ? 'Campo obligatorio' : ''
                                                                            }
                                                                        </span>
                                                                        <span className={styles.spanCounter}>
                                                                            {formik?.values?.[field.name]?.toString().length || 0}/{field.maxLength}
                                                                        </span>
                                                                    </Box>
                                                                </>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }
                }
            </Formik>

            {
                !!acceptedValueHasValue && <AddCreditNotesForm 
                    selectedDocument={selectedDocument} 
                    readOnly={readOnly} 
                    creditNoteType={CreditNoteType.NoAgree}
                    acceptedValue={acceptedValueHasValue}
                />
            }

            {
                !readOnly &&
                    <Grid container direction={"row"} justifyContent={'center'} className='buttonContainer'>
                        <Button 
                            variant='contained' 
                            color='secondary' 
                            className='buttonSubmit' 
                            disabled={!formIsValid} 
                            data-testid='submit-button' 
                            onClick={ formRef.current?.submitForm }
                        >
                            Guardar
                        </Button>
                        <Button 
                            variant='contained' 
                            color='secondary' 
                            className='buttonCancelar' 
                            onClick={ closeModal }
                            data-testid='cancel-button'
                        >
                            Cancelar
                        </Button>
                    </Grid>
            }
            
            {isLoading && <Spinner/>}
        </Fragment>
    )
}
