import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch, GET_ELECTRONIC_DOCS, ISupports, RootState, apiInstance } from "shared";
import { ILoadSupports } from "../model/ILoadSupports";
import Swal from "sweetalert2";

export const useLoadSupports = ( { idDoc }: ILoadSupports ) => {
 
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const { response, supports, loading } = useSelector((state: RootState) => state.linkSupports);
  const { bill, activityStatus } =  useSelector((state: RootState) => state.auditDetails);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState(false);

  const typeAccept = ["PDF","JPG","PNG","DOCX","XLSX","PPTX", "HTML", "ZIP"];
  const maxLength= 7;
  const maxSize= 17;
  
  const handleChangeOpenModal = (value:boolean)=>{
    const currentDocs =  supports.map(doc => {return {code: doc.code, name:doc.name, id: doc.id}} );
    setHasErrors(false);
    dispatch.linkSupports.addSupports(currentDocs);
    dispatch.linkSupports.cleanState();
    setOpenModal(value)
  }

  const handleChangeFile = ( file: File, code:string ) => {
    const arrayFiles = Array.from([file])

    if (file && file.size < (7000000) ) {
      const currentDocs =  supports.map(doc => doc.id === code ? {...doc, file, originalName: file.name} : doc);
      setHasErrors(false);
      dispatch.linkSupports.addSupports(currentDocs);

      dispatch.linkSupports.getLinkAsync({
        file: arrayFiles, 
        url: `${GET_ELECTRONIC_DOCS}/${idDoc}/supports/generate-upload-link`,
        codes: [code]
      })
    }
  };

  const errorFileType = () => {
    Swal.fire({
      title: 'Carga fallida',
      text: `Debe garantizar que el formato y las extensiones de los soportes sea una de las siguientes: PDF,JPG,PNG,XLSX, DOCX,PPTX, HTML Y ZIP `,
      icon: 'error',
      timer: 5500
    })
  }

  const errorFileSize = () => {
    Swal.fire({
      title: 'Carga fallida',
      text: `Debe garantizar que el tamaño máximo de cada soporte no sea superior a ${maxLength}MB`,
      icon: 'error',
      timer: 5500
    })
  }

  const errorLengthFiles = () => {
    Swal.fire({
      title: 'Carga fallida',
      text: `El máximo de archivos permitidos es de ${maxSize}`,
      icon: 'error',
      timer: 5500
    })
  }

  const removeFile = (file: ISupports) => {
    const currentDocs =  supports.map(doc => doc.code === file.code ? {code: doc.code, name:doc.name, id: doc.id} : doc);
    const filterDocuments = response?.filter(doc => doc.originalName !== file.originalName);
    setHasErrors(false);
    dispatch.linkSupports.addSupports(currentDocs);
    dispatch.linkSupports.getLink(filterDocuments);
  };

  const validatedFiles =(element:any) => element.hasOwnProperty('file');

  const handleOnclickUpload = async(noRequired?:boolean) => {
    const numberOfRequiredItems = supports?.filter( item => item.name.includes('(Obligatorio)'))
    const elementsWithFile = numberOfRequiredItems?.filter(validatedFiles);
    if(noRequired || elementsWithFile.length === numberOfRequiredItems.length){
      Swal.fire({
        icon: "warning",
        title: `Atención`,
        text: `¿ Esta seguro que desea radicar la Factura # ${bill}?`,
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText:"Cancelar",
      })?.then(async (result) => {
        if (result.isConfirmed) {
          dispatch.linkSupports.isLoading(true);
          await apiInstance.post(`${GET_ELECTRONIC_DOCS}/${idDoc}/supports`, {
            attachmentIds: response?.map(res=> res.id),
          }).then((res)=>{
            Swal.fire({
              icon: "success",
              title: `Atención`,
              text: `Se ha radicado la Factura #${bill} de manera exitosa. Número de radicado: ${res.data.filingNumber}`,
              confirmButtonText: "Aceptar",
            })?.then(async (resultSwal) => {
              if (resultSwal.isConfirmed) {
                dispatch.electronicDocuments.getElectronicDocumentAsync({id:idDoc})
                navigate(-1);
              }
            })
          }).finally(()=>{
            dispatch.linkSupports.isLoading(false)
          });
        }
      })
    } else {
      setHasErrors(true);
      Swal.fire({
        icon: "error",
        title: `Atención`,
        text: `Debe garantizar la carga de los soportes Obligatorios`,
        confirmButtonText: "Aceptar",
      })
    }
  }

  return {
    hasErrors,
    typeAccept,
    loading,
    maxLength,
    supports,
    openModal,
    bill,
    maxSize,
    response,
    activityStatus,
    
    handleOnclickUpload,
    removeFile,
    errorFileSize,
    errorFileType,
    handleChangeFile,
    handleChangeOpenModal,
    errorLengthFiles
  }

}
