
import React, { useEffect, useState } from 'react'
import { Layout } from '../../shared/utils/ui/Layout';
import style from './styles/submitInvoce.module.scss'
import { Button, IconButton, Step, StepLabel, Stepper, Typography } from '@mui/material';
import Image from 'shared/assets/index';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyleSubmitInvoice } from './hooks/useStyleSubmitInvoice';
import { PrepareInvoice } from 'pages/prepare-invoice/PrepareInvoice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import { activities, statesActivities } from 'shared/models/lineStates';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import { LoadRips } from 'pages/read-documents/components/LoadRips';
import { LoadSupports } from 'pages/read-documents/components/LoadSupports';
import { EResponseActivityStatus } from 'shared/constants/api.enum';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const SubmitiInvoice = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { id }= useParams();
  const { billingState, activityStatus } = useSelector((state: RootState) => state.auditDetails);
  const { callGetDetailedDocument } = useDetailedConsultation();
  const [activeStep, setActiveStep] = useState(0);
  const [isHadleSubmit, setIsHadleSubmit] = useState(true);
  const [isNextPage, setIsNextPage] = useState(true);

  useEffect(() => {
    if(isNextPage){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsNextPage(false)
    }
  },[isNextPage])

  useEffect(() => {
    if(activityStatus?.name === EResponseActivityStatus.FAIL && (activeStep !== 0)  ){
      dispatch.messageModal.setMessageModal({
        show: true, 
        title: "Atención",
        size: 'medium',
        description:(
          <div className='contentDescriptionModalAnimation' data-testid='content_description'>
            <ErrorOutlineIcon className='iconButton'/>
            <Typography style={{fontSize:'clamp(0.9rem, 0.3042rem + 1.48vw, 1.2rem)'}}>
              Señor usuario, según la <b>Resolución 2284 de 2024</b>, la Secretaría Seccional de Salud y Protección Social de Antioquia debe aplicar una devolución con el código <b>DE56</b> por extemporaneidad en la radicación. 
              Tenga en cuenta que para hacer efectiva la devolución es necesario que envíe una Nota Crédito por el valor total de la factura,  una vez suceda esto la  factura  será marcada como anulada. Una vez hecho este proceso, podrá reenviar una nueva factura y radicarla de acuerdo a los tiempos de la norma.
            </Typography>
          </div>
        ),
        onClose() {
          dispatch.messageModal.cleanStateMessage()
        },
        onOk(){
          dispatch.messageModal.cleanStateMessage()
        },
        OkTitle: 'Aceptar',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isNextPage, activeStep])

  const onNavigateBack = () => navigate(-1);

  const {ColorlibConnector, ColorlibStepIcon } = useStyleSubmitInvoice();

  const steps = [
    {
      label: "Clasificación de factura",
      description: <PrepareInvoice isHadleSubmit={isHadleSubmit} setIsHadleSubmit={setIsHadleSubmit} setIsNextPage={setIsNextPage} />,
    },
    {
      label: "Cargar RIPS",
      description: <LoadRips idDoc={id} isHadleSubmit={isHadleSubmit} setIsHadleSubmit={setIsHadleSubmit} setIsNextPage={setIsNextPage} />,
    },
    {
      label: "Cargar soportes",
      description: <LoadSupports isHadleSubmit={isHadleSubmit} setIsHadleSubmit={setIsHadleSubmit} idDoc={id} />,
    },
  ];

  const handleNext = () => {
    setIsHadleSubmit(true)
  };

  const handleBack = () => {
    if(activeStep === 0){
      navigate(-1);
    }else{
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setIsNextPage(false)
    }
  };

  useEffect(() => {
    if (billingState.code !== '' && !statesActivities.received.includes(billingState.code)) {
      navigate('/');
    }

    const stepMap = {
      [activities.FacturaClasificada]: 1,
      [activities.RIPSValidados]: 2,
    };

    const currentStep = stepMap[billingState.code] || 0;
    setActiveStep(currentStep);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingState])

  useEffect(() => {
    callGetDetailedDocument(id || '');
    dispatch.menuDrawer.changeSelectedIndex(1);
    return ()=>{
      dispatch.auditDetails.setCleanBillingState()
      dispatch.linkSupports.cleanSupports()
      dispatch.linkSupports.cleanState()
      dispatch.linkDocument.cleanState()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <div className={style.containerSeeContact} data-testid="see-contact">
        <section className={style.conteinerHeader}>
            <Typography className={style.title}> Radicar Facturas </Typography>
            <IconButton
              className={`${style['viewButton']} ${style['noHover']}`}
              onClick={onNavigateBack}
              data-testid="button-navigate-back"
            >
              <img src={Image.iconoCerrar} className={`${style['sizeImg']}`} alt="cerrar" />
            </IconButton>
        </section>

        <section className={style.containerStepper}>
          <Stepper 
            activeStep={activeStep}
            connector={<ColorlibConnector/>}
            className={style.contentStepper}
            alternativeLabel
          >
            {steps.map((label, index) => {
              return (
                <Step key={label.label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon} className={style.stepLabel} color='#6D6D6D'>{label.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </section>

        <section className={style.contentFormulario}>
          <div className={style.formContainer}>
            {steps[activeStep].description}
          </div>

          <div className={style.containerButtons}>
            <Button
              className={style.buttonCancel}
              onClick={handleBack}
              startIcon={ activeStep >= 1 ? <ArrowBackIosIcon/> : null}
              data-testid="button-back"
            >
              { activeStep >= 1 ? "Anterior" : "Cancelar"}
            </Button>
            <Button
              className={style.buttonNext}
              onClick={() => {
                handleNext();
              }}
              endIcon={activeStep === steps.length - 1 ?  null : <ArrowForwardIosIcon/>}
              data-testid="button-next"
              disabled={activityStatus?.name === EResponseActivityStatus.FAIL && (activeStep === 2)}
            >
              {activeStep === steps.length - 1 ? "Radicar factura" : "Siguiente"}
            </Button>
          </div>
        </section>

      </div>
    </Layout>
  )
}
