import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { RootState } from "shared/store/store";
import { CommentItem } from "./CommentItem";
import styles from './styles/CommentsList.module.scss';
import { debounceFunction } from "../../utils/helpers";
import { useEffect, useState } from "react";

export const CommentsList = () => { 
    const { comments } = useSelector((state: RootState) => state);
    const [displayedComments, setDisplayedComments] = useState<number>(3);
    const [key, setKey] = useState<number>(0);

    const validateScrollEnd = (value: any) => {
        const { scrollTop, scrollHeight, clientHeight } = value.target;

        debounceFunction(() => {
          const esFinalScroll =  (clientHeight + scrollTop + 10) >= scrollHeight;
          if(esFinalScroll ) {
            setDisplayedComments(prevCount => prevCount + 3); 
          } 
        });
    }   

    useEffect(() => {
        // Incrementar la clave cada vez que comments cambie y recargue el CommentItem
        setKey(prevKey => prevKey + 1);
    }, [comments]);

    return (
        <Box className={styles.commentListContainer} data-testid='comments_list'>
            <h6>Comentarios</h6>
            <Box className={styles.commentsList} onScroll={validateScrollEnd} data-testid='box_content'>
                {
                   comments?.comments.slice(0, displayedComments).map((comment, index) => (
                        <CommentItem
                            key={`${comment.id}_${key}`}
                            id={comment.id}
                            comment={comment.message}
                            author={comment.author}
                            date={comment.date}
                            wasUpdated={comment.wasUpdated}
                            privacy={comment.privacy}
                            data-testid={`comment_${index}`}
                            modifiedAt={comment?.modifiedAt || ''}
                        />
                    ))
                }
            </Box>
        </Box>
    )
}
